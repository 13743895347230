import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { CountryFormConfiguration, DEFAULT_COUNTRY_CODES } from "../constants";
import { defaultCountryConfigFieldLengths } from "./defaults";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import { VisibleOnRule } from "../model/VisibleOnRule";
import {
  DsaMyLogo,
  FpxLogo,
  MastercardLogo,
  VisaLogo,
} from "../../components/common/Logo/models/Logo";

const ALPHABETS_AND_SPECIAL_CHARS = new RegExp(/^[a-zA-Z@\-.,/'#\s]*$/);
const ALPHANUMERIC_AND_SPECIAL_CHARS = new RegExp(/^[a-zA-Z0-9@\-.,/'#\s]*$/);
const ALPHANUMERIC = new RegExp(/^[a-zA-Z0-9\s]*$/);

const statesLabels = [
  "JOHOR",
  "KEDAH",
  "KELANTAN",
  "KUALA LUMPUR",
  "LANGKAWI",
  "MELAKA",
  "NEGERI SEMBILAN",
  "PAHANG",
  "PERAK",
  "PERLIS",
  "PULAU PINANG",
  "PUTRAJAYA",
  "TERENGGANU",
  "TIOMAN",
  "SARAWAK",
  "LAWAS",
  "SABAH",
];

const ethnicityLabels = ["CHINESE", "MALAY", "INDIAN", "OTHERS"];

export const Malaysia = (locale: string): CountryFormConfiguration => {
  return {
    id: locale,
    forms: {
      personalInfo: {
        beenMemberBefore: {
          name: "beenMemberBefore",
          label: "PREVIOUS_MEMBERSHIP",
          labelExtraClassNames: ["enlargedLabelText"],
          type: "radio",
          options: [
            { value: true, label: "YES" },
            { value: false, label: "NO" },
          ],
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
          },
          classNames: ["centerJustified"],
          bottomDescription: {
            rule: {
              visibleOn: [
                "beenMemberBefore",
                (value: boolean) => value === true,
              ] as VisibleOnRule<boolean>,
            },
            text: "PREVIOUS_MEMBERSHIP_DESCRIPTION",
            individualStyling: ["centeredAndBold"],
          },
          validationErrorStyling: ["textCentered"],
        },
        lastName: {
          name: "lastName",
          label: "LAST_NAME",
          type: "text",
          sanitize: true,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            hasNoOnlySpecialSigns: {
              value: true,
              errorMessage: "LAST_NAME_SIZE",
            },
            maxLength: {
              value: defaultCountryConfigFieldLengths.lastName,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHABETS_AND_SPECIAL_CHARS,
              errorMessage: "LAST_NAME_SIZE",
            },
            trim: true,
          },
        },
        firstName: {
          name: "firstName",
          label: "FIRST_NAME",
          type: "text",
          sanitize: true,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            hasNoOnlySpecialSigns: {
              value: true,
              errorMessage: "FIRST_NAME_SIZE",
            },
            maxLength: {
              value: defaultCountryConfigFieldLengths.firstName,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHABETS_AND_SPECIAL_CHARS,
              errorMessage: "FIRST_NAME_SIZE",
            },
            trim: true,
          },
        },
        ethnicity: {
          name: "ethnicity",
          label: "ETHNICITY",
          type: "asyncAutocomplete",
          getOptions: () => ethnicityLabels,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
          },
        },
        addressLine1: {
          name: "addressLine1",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: {
              value: getForbiddenWordsForCountry(locale),
              errorMessage: "PO_BOX",
            },
            trim: true,
          },
        },
        addressLine2: {
          name: "addressLine2",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: false, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: {
              value: getForbiddenWordsForCountry(locale),
              errorMessage: "PO_BOX",
            },
            trim: true,
          },
        },
        city: {
          name: "city",
          label: "CITY",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 30,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        state: {
          name: "state",
          label: "STATE",
          type: "asyncAutocomplete",
          getOptions: () => statesLabels,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        postalCode: {
          name: "postalCode",
          label: "POSTAL_CODE",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: { value: 5, errorMessage: "TOO_LONG" },
            minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
            pattern: { value: /^\d{5}$/, errorMessage: "POSTAL_CODE_SIZE" },
          },
        },
        dateOfBirth: {
          name: "dateOfBirth",
          label: "BIRTHDAY",
          type: "date",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
          },
        },
        registrationNumber0: {
          name: "registrationNumber0",
          label: "REGISTRATION_NUMBER0",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 12,
              errorMessage: "REGISTRATION_NUMBER_SIZE",
            },
            minLength: {
              value: 12,
              errorMessage: "REGISTRATION_NUMBER_SIZE",
            },
            pattern: {
              value: /[0-9]{6}-[0-9]{2}-[0-9]{4}$/,
              errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
            },
            trim: true,
            mask: "000000-00-0000",
          },
        },
        registrationNumber0Attachment0: {
          name: "registrationNumber0-attachment0",
          label: "UPLOAD_FILE_INFO_RESIDENT0",
          type: "file",
          index: 1,
          attachmentType: AttachmentType.TAX_NUMBER,
          middleDescription: {
            text: "REGISTRATION_DOCUMENT0_MIDDLE_DESCRIPTION",
          },
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            acceptedExtensions: {
              value: [".pdf", ".bmp", ".jpg", ".jpeg"],
              errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
            },
          },
        },
        registrationNumber1: {
          name: "registrationNumber1",
          label: "REGISTRATION_NUMBER1",
          type: "text",
          validation: {
            required: { value: false, errorMessage: "REQUIRED" },
            maxLength: {
              value: 15,
              errorMessage: "REGISTRATION_NUMBER_SIZE",
            },
            minLength: {
              value: 15,
              errorMessage: "REGISTRATION_NUMBER_SIZE",
            },
            pattern: {
              value: /^[A-Za-z][0-9]{2}-\d{2}(0[1-9]|1[0-2])-\d{8}$/,
              errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
            },
            trim: true,
            mask: "a00-0000-00000000",
          },
        },
      },
      shippingAddress: {
        careOfName: {
          name: "careOfName",
          label: "HMP_CARE_OF_NAME",
          type: "text",
          sanitize: true,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 53,
              errorMessage: "HMP_CARE_OF_NAME_SIZE",
            },
            pattern: {
              value: /.*\S.*/,
              errorMessage: "REQUIRED",
            },
            hasNoOnlySpecialSigns: {
              value: true,
              errorMessage: "REQUIRED",
            },
            trim: true,
          },
        },
        addressLine1: {
          name: "addressLine1",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: {
              value: getForbiddenWordsForCountry(locale),
              errorMessage: "PO_BOX",
            },
            trim: true,
          },
        },
        addressLine2: {
          name: "addressLine2",
          label: "ADDRESS",
          type: "text",
          validation: {
            required: { value: false, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "ADDRESS_SIZE",
            },
            pattern: {
              value: ALPHANUMERIC_AND_SPECIAL_CHARS,
              errorMessage: "FORBIDDEN_WORD",
            },
            forbiddenWords: {
              value: getForbiddenWordsForCountry(locale),
              errorMessage: "PO_BOX",
            },
            trim: true,
          },
        },
        city: {
          name: "city",
          label: "CITY",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 30,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        state: {
          name: "state",
          label: "STATE",
          type: "asyncAutocomplete",
          getOptions: () => statesLabels,
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: {
              value: 40,
              errorMessage: "TOO_LONG",
            },
            pattern: {
              value: ALPHANUMERIC,
              errorMessage: "FORBIDDEN_WORD",
            },
            trim: true,
          },
        },
        postalCode: {
          name: "postalCode",
          label: "POSTAL_CODE",
          type: "text",
          validation: {
            required: { value: true, errorMessage: "REQUIRED" },
            maxLength: { value: 5, errorMessage: "TOO_LONG" },
            minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
            pattern: { value: /^\d{5}$/, errorMessage: "POSTAL_CODE_SIZE" },
          },
        },
      },
    },
    addressLength: 40,
    cityLength: 30,
    postalCodeLength: 5,
    postalCodeMask: false,
    postalCodePattern: /^\d{5}$/,
    postalCodeRequired: true,
    province: false,
    provinceLength: defaultCountryConfigFieldLengths.province,
    firstNameLength: defaultCountryConfigFieldLengths.firstName,
    genderFieldRequired: false,
    lastNameLength: defaultCountryConfigFieldLengths.lastName,
    lastNameFirst: false,
    middleInitial: true,
    middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
    middleInitialRequired: false,
    mothersMaidenName: false,
    mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
    mothersMaidenNameRequired: false,
    showDateOfBirthDisclaimer: false,
    showDocumentationBox: false,
    showHmpBenefits: true,
    showHmpPickUpSection: false,
    showHmpPriceContent: true,
    showLatinInfo: false,
    showLinkToCookiePolicy: true,
    showLinkToOrderConfirmation: true,
    showPdfContainer: true,
    showResidentRadioBox: false,
    splitDeliveryTotal: false,
    spouseFirstName: false,
    spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
    spouseMiddleName: false,
    spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
    spouseLastName: false,
    spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
    showOrderHandlerInformation: false,
    supportedCountryCodes: DEFAULT_COUNTRY_CODES,
    showSponsorshipDisclaimer: true,
    hideFooterTerms: true,
    footerLogos: [DsaMyLogo, VisaLogo, MastercardLogo, FpxLogo],
  } as const;
};
