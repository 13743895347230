import React, { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../../../components/common/Input";
import { FormFieldError } from "../../../utils/formsUtils";

type SponsorNameInputProps = {
  disabled: boolean;
  error?: FormFieldError;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange: (value: string) => void;
  value: string | null | undefined;
};

export const SponsorNameInput = forwardRef(
  (props: SponsorNameInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { disabled, error, onBlur, onChange, value } = props;
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange?.(event.target?.value);

    return (
      <Input
        disabled={disabled}
        required
        error={error}
        id="sponsorName"
        label={t("SPONSOR_NAME")}
        maxLength={3}
        name="sponsorName"
        onBlur={onBlur}
        onChange={handleChange}
        type="text"
        value={value || ""}
        ref={ref}
      />
    );
  }
);
