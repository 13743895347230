import {
  PersonalDetailsState,
  usePersonalDetailsStore,
} from "./personalDetailsStore";
import { WithTypeContaining } from "../../../types/utilityTypes";

export const personalDetailsFromStore = (): PersonalDetailsState => ({
  firstName: usePersonalDetailsStore.getState().firstName,
  lastName: usePersonalDetailsStore.getState().lastName,
  mothersMaidenName: usePersonalDetailsStore.getState().mothersMaidenName,
  middleInitial: usePersonalDetailsStore.getState().middleInitial,
  gender: usePersonalDetailsStore.getState().gender,
  spouseFirstName: usePersonalDetailsStore.getState().spouseFirstName,
  spouseMiddleName: usePersonalDetailsStore.getState().spouseMiddleName,
  spouseLastName: usePersonalDetailsStore.getState().spouseLastName,
  dateOfBirth: usePersonalDetailsStore.getState().dateOfBirth,
  cityOfBirth: usePersonalDetailsStore.getState().cityOfBirth,
  placeOfBirthSelection:
    usePersonalDetailsStore.getState().placeOfBirthSelection,
  placeOfBirthCountry: usePersonalDetailsStore.getState().placeOfBirthCountry,
});

export const createPersonalDetailsFromForm = ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  cityOfBirth,
  placeOfBirthSelection,
  placeOfBirthCountry,
}: WithTypeContaining<PersonalDetailsState>): PersonalDetailsState => ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  cityOfBirth,
  placeOfBirthSelection,
  placeOfBirthCountry,
});

export const createPersonalDetailsRequest = ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  cityOfBirth,
  placeOfBirthCountry,
}: WithTypeContaining<PersonalDetailsState>) => ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  cityOfBirth: cityOfBirth,
  placeOfBirth: {
    country: placeOfBirthCountry || "",
    city: "",
    province: "",
  },
});
