import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { CountryFormConfiguration } from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";
import { staticAssetsApi } from "../../api/staticAssets";
import { queryClient } from "../queryClient";
import { VisibleOnRule } from "../model/VisibleOnRule";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import { DsaLogo } from "../../components/common/Logo/models/Logo";

type TaiwanAddress = {
  state: string;
  city: string;
  postalCode: string;
};

const TAIWAN_NAME_PATTERN = RegExp(
  /^[\u4e00-\u9fff\p{Lower}\p{Upper}\s\-'ʻ`‘\d]*$/u,
  "u"
);
const ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS = new RegExp(
  /^[\u4e00-\u9fff\p{Lower}\p{Upper}\s\-,./'’#()@\d]*$/u,
  "u"
);

const getAddressData = async () =>
  await queryClient.fetchQuery({
    queryKey: ["zh-TW", "addresses"],
    queryFn: async (): Promise<TaiwanAddress[]> =>
      await staticAssetsApi.getStaticAssets<TaiwanAddress[]>(
        "zh-tw",
        "addresses.json"
      ),
    staleTime: Infinity,
    gcTime: Infinity,
  });

const getAddresses = async () =>
  queryClient.getQueryData<TaiwanAddress[]>(["zh-TW", "addresses"]) ??
  (await getAddressData());

const getStates = async (): Promise<string[]> => [
  ...new Set((await getAddresses()).map((address) => address.state)),
];

const getCities = async (selectedState: string): Promise<string[]> => {
  const cities = (await getAddresses())
    .filter((address) => address.state === selectedState)
    .map((address) => address.city);

  return [...new Set(cities)];
};

const getPostalCodes = async (selectedCity: string): Promise<string[]> => {
  const postalCodes = (await getAddresses())
    .filter((address) => address.city === selectedCity)
    .map((address) => address.postalCode);

  return [...new Set(postalCodes)];
};

export const Taiwan: CountryFormConfiguration = {
  id: "zh-TW",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        bottomDescription: {
          rule: {
            visibleOn: [
              "beenMemberBefore",
              (value: boolean) => value === true,
            ] as VisibleOnRule<boolean>,
          },
          text: "PREVIOUS_MEMBERSHIP_DESCRIPTION",
          individualStyling: ["centeredAndBold"],
        },
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: { text: "FIRST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: TAIWAN_NAME_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: { text: "LAST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: TAIWAN_NAME_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("zh-TW"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      state: {
        name: "state",
        label: "STATE",
        type: "asyncAutocomplete",
        defaultValue: "",
        getOptions: getStates,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["state"],
        getOptions: getCities,
        rule: {
          visibleOn: ["state", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        defaultValue: "",
        dependenciesNames: ["city"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 10,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 10,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: /^[a-zA-Z]{1}[1-2]{1}[0-9]{8}$/,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: [".jpg", ".png", ".pdf"],
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("zh-TW"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      state: {
        name: "state",
        label: "STATE",
        type: "asyncAutocomplete",
        getOptions: getStates,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "asyncAutocomplete",
        dependenciesNames: ["state"],
        getOptions: getCities,
        rule: {
          visibleOn: ["state", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "asyncAutocomplete",
        dependenciesNames: ["city"],
        getOptions: getPostalCodes,
        rule: {
          visibleOn: ["city", Boolean],
        },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: ALPHA_NUMERIC_WITH_SPECIAL_SYMBOLS,
  cityLength: 40,
  postalCodeLength: 3,
  postalCodeMask: "000",
  postalCodePattern: /^[0-9]{3}$/,
  postalCodeRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "Taiwan",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [DsaLogo],
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  placeOfBirthRequired: false,
  hideFooterTerms: true,
  showDataPrivacyAcknowledgment: true,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showFirstNameDisclaimer: true,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLastNameDisclaimer: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["zh"],
  defaultCountryCode: "+886|Taiwan",
} as const;
