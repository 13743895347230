import { useQuery } from "@tanstack/react-query";
import { configApi } from "../../api/config";
import { useConfigurationStore } from "./configurationStore";
import { CardProvider, PaymentMethod } from "../payment/types/PaymentMethod";
import { usePaymentStore } from "../payment/stores/paymentStore";
import { useAuthenticationStore } from "../authentication/authenticationStore";
import { useHmpOwnershipStore } from "../personal-info/hmpOwnership/hmpOwnershipStore";
import { logger } from "../../utils/logger";

type SupportedPaymentMethods = {
  methods: PaymentMethod[];
  cardProviders: CardProvider[];
};

export enum MemberpackObtainingMethod {
  MEMBERPACK_OBTAINED_OUTSIDE_OA = "MEMBERPACK_OBTAINED_OUTSIDE_OA",
  MEMBERPACK_NOT_REQUIRED = "MEMBERPACK_NOT_REQUIRED",
  MEMBERPACK_PURCHASE_VIA_OA = "MEMBERPACK_PURCHASE_VIA_OA",
}

export type ConfigurationResponse = {
  version: string;
  supportedPaymentMethods: SupportedPaymentMethods;
  supportedMemberpackObtainingMethods: MemberpackObtainingMethod[];
};

const { setVersion } = useConfigurationStore.getState();
const { setPaymentMethodsAndCardProviders } = usePaymentStore.getState();
const { setSupportedMemberpackObtainingMethods } =
  useHmpOwnershipStore.getState();
const fetchConfig = async () => {
  try {
    const data = await configApi.fetchConfig();
    const {
      version,
      supportedPaymentMethods: { methods, cardProviders },
      supportedMemberpackObtainingMethods,
    } = data;
    setVersion(version);
    setPaymentMethodsAndCardProviders(methods, cardProviders);
    setSupportedMemberpackObtainingMethods(supportedMemberpackObtainingMethods);
    return data;
  } catch (error) {
    logger.info("Failed to get app config.");
    logger.error(error as Error);
    throw error;
  }
};

export const useConfigurationQuery = () =>
  useQuery<ConfigurationResponse>({
    queryKey: ["Configuration"],
    queryFn: fetchConfig,
    enabled:
      useAuthenticationStore.getState().isAuthenticated() &&
      useConfigurationStore.getState().isVersionNotObtained(),
    retry: 3,
  });
