import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import {
  CountryFormConfiguration,
  DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
  DEFAULT_NAMES_PATTERN,
  Gender,
  ONLY_DIGITS_REGEX,
} from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
} from "./defaults";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import {
  MastercardLogo,
  VisaLogo,
} from "../../components/common/Logo/models/Logo";

export const Indonesia: CountryFormConfiguration = {
  id: "id-ID",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: { text: "FIRST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        bottomDescription: { text: "LAST_NAME_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      gender: {
        name: "gender",
        label: "GENDER",
        type: "radio",
        options: [
          { value: Gender.MALE, label: "MALE" },
          { value: Gender.FEMALE, label: "FEMALE" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        validationErrorStyling: ["textCentered"],
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      district: {
        name: "district",
        label: "DISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        bottomDescription: { text: "BIRTHDAY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 16,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 16,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: ONLY_DIGITS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        bottomDescription: { text: "DELIVERY_DISCLAIMER" },
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("id-ID"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      addressLine2: {
        name: "addressLine2",
        label: "ADDRESS_LINE_2",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      district: {
        name: "district",
        label: "DISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "TOO_LONG",
          },
        },
      },
      subdistrict: {
        name: "subdistrict",
        label: "SUBDISTRICT",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "00000",
        },
      },
    },
  },
  addressLength: 40,
  addressPattern: DEFAULT_ADDRESS_LINE_PATTERN,
  cityLength: 40,
  postalCodeLength: 5,
  postalCodeMask: "00000",
  postalCodePattern: /^[0-9]{5}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: 40,
  provinceRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "Indonesia",
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [VisaLogo, MastercardLogo],
  genderFieldRequired: true,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  placeOfBirthRequired: false,
  showDataPrivacyAcknowledgment: true,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showFirstNameDisclaimer: true,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLastNameDisclaimer: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["id"],
  defaultCountryCode: "+62|Indonesia",
  showSponsorshipDisclaimer: true,
} as const;
