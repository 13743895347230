import { useMutation } from "@tanstack/react-query";
import { useProfileStore } from "./profileStore";
import { ResponseError } from "../common/api/ResponseError";
import { smsVerification } from "../../api/smsVerification";
import { logger } from "../../utils/logger";

const { verifyNumber } = useProfileStore.getState();

type VerifyPhoneNumberRequest = {
  countryCode: string;
  phoneNumber: string;
};
export const useVerifyPhoneNumberMutation = () => {
  return useMutation<unknown, ResponseError, VerifyPhoneNumberRequest>({
    mutationFn: (request: VerifyPhoneNumberRequest) => {
      return smsVerification.verifyPhoneNumber(
        request.countryCode,
        request.phoneNumber
      );
    },
    onSuccess: () => {
      verifyNumber();
    },
    onError: (error) => {
      logger.error("Failed to verify phone number", error);
    },
  });
};
