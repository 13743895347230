import { WithTypeContaining } from "../../../types/utilityTypes";
import {
  PublicAdministrationState,
  usePublicAdministrationStore,
} from "./publicAdministrationStore";

export const publicAdministrationFromStore = () => {
  return {
    isPublicAdministrationEmployee:
      usePublicAdministrationStore.getState().isPublicAdministrationEmployee,
  };
};

export const publicAdministrationFromForm = ({
  isPublicAdministrationEmployee,
}: WithTypeContaining<PublicAdministrationState>): PublicAdministrationState => ({
  isPublicAdministrationEmployee: isPublicAdministrationEmployee as string,
});
