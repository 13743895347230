import axios from "axios";

export type AdministrativeAreasResponse = {
  page: number;
  totalPages: number;
  totalElements: number;
  values: string[];
};

const getAdministrativeAreas = async (
  locale: string,
  areas: string[],
  page: number,
  size: number
): Promise<AdministrativeAreasResponse> => {
  const encodedAndSeparatedAreas = areas
    .map((area) => encodeURIComponent(area))
    .join(encodeURIComponent("|"));
  const response = await axios.get<AdministrativeAreasResponse>(
    `/v1/administrative-areas/${locale}${areas.length > 0 ? `/${encodedAndSeparatedAreas}` : ""}?page=${page}&size=${size}`
  );

  return response.data;
};

export const avsApi = {
  getAdministrativeAreas,
};
