import { useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { VALIDATION_RULES } from "../../config/constants";
import { useProfileStore } from "./profileStore";
import { useShallow } from "zustand/react/shallow";

export const useDefaultPhoneCountryCode = (
  setValue: UseFormSetValue<{ countryCode: string; phoneNumber: string }>
) => {
  const country = useProfileStore(useShallow((state) => state.country));
  const defaultCountryCode = VALIDATION_RULES[country]?.defaultCountryCode;

  useEffect(() => {
    if (defaultCountryCode) {
      setValue("countryCode", defaultCountryCode as string);
    }
  }, [country]);

  return defaultCountryCode;
};
