export enum RegistrationEvent {
  SmsSend = "SmsSend",
  PinCodeVerified = "PinCodeVerified",
  OnlineCodesVerified = "OnlineCodesVerified",
  PersonalInfoSubmitted = "PersonalInfoSubmitted",
  GoldStandardAccepted = "GoldStandardAccepted",
  AgreementsAccepted = "AgreementsAccepted",
  PaymentStarted = "PaymentStarted",
  PaymentDone = "PaymentDone",
  RegistrationCompleted = "RegistrationCompleted",
}
