import { create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import i18next from "i18next";
import { RegistrationEvent, useEventStore } from "../events/eventStore";

interface ProfileState {
  country: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
}

interface MemberState {
  memberId: string;
}
interface ProfileVerificationState {
  numberVerified: boolean;
  pinCodeVerified: boolean;
  phoneAlreadyConfirmedInHerbalifeSystem: boolean;
}

interface ProfileAction {
  verifyNumber: () => void;
  verifyPin: () => void;
  verifyProfile: () => void;
  isPhoneEditable: () => boolean;
  resetAllVerification: () => void;
  resetMobileVerification: () => void;
  isProfileVerified: () => boolean;
  setCountry: (country: string) => Promise<void>;
  setProfile: (profile: ProfileState) => void;
  setMemberId: (memberId: string) => void;
  setMobile: (countryCode: string, mobileNumber: string) => void;
  setInitialRegistrationData: (
    country: string,
    email: string,
    isPhoneNumberConfirmed: boolean
  ) => Promise<void>;
  reset: () => void;
}

export type ProfileStore = ProfileState &
  ProfileVerificationState &
  MemberState &
  ProfileAction;

const initialState: ProfileState & ProfileVerificationState & MemberState = {
  country: "",
  email: "",
  countryCode: "",
  phoneNumber: "",
  memberId: "",
  numberVerified: false,
  pinCodeVerified: false,
  phoneAlreadyConfirmedInHerbalifeSystem: false,
};

const initializeCountry = async (newCountry: string) => {
  await i18next.changeLanguage(newCountry);
};

export const useProfileStore = create<ProfileStore>()(
  devtools(
    subscribeWithSelector(
      persist(
        (set, get) => ({
          ...initialState,
          isProfileVerified: () =>
            get().numberVerified && get().pinCodeVerified,
          verifyNumber: () => set({ numberVerified: true }),
          verifyPin: () => set({ pinCodeVerified: true }),
          verifyProfile: () =>
            set({
              numberVerified: true,
              pinCodeVerified: true,
              phoneAlreadyConfirmedInHerbalifeSystem: true,
            }),
          isPhoneEditable: () => {
            return !get().phoneAlreadyConfirmedInHerbalifeSystem;
          },
          resetAllVerification: () =>
            set({
              numberVerified: false,
              pinCodeVerified: false,
            }),
          resetMobileVerification: () =>
            set({
              numberVerified: false,
              pinCodeVerified: false,
            }),
          // TODO used only for test purposes - we should remove it
          setCountry: async (newCountry) => {
            set({
              country: newCountry,
            });
            await initializeCountry(newCountry);
          },
          setProfile: (profile) => {
            set({
              country: profile.country,
              email: profile.email,
              countryCode: profile.countryCode,
              phoneNumber: profile.phoneNumber,
            });
          },
          setMemberId: (memberId) => {
            set({ memberId });
          },
          setMobile: (countryCode, phoneNumber) => {
            set({
              countryCode,
              phoneNumber,
            });
          },
          setInitialRegistrationData: async (
            country,
            email,
            isPhoneNumberConfirmed
          ) => {
            set({
              country,
              email,
            });
            await initializeCountry(country);

            if (isPhoneNumberConfirmed) {
              useEventStore
                .getState()
                .publish(RegistrationEvent.PinCodeVerified);
              get().verifyProfile();
            }
          },
          reset: () => {
            set(initialState);
          },
        }),
        {
          name: "profile-storage",
          storage: createJSONStorage(() => sessionStorage),
        }
      )
    )
  )
);
