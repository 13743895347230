import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { createPersonalDetailsFromForm } from "./personalDetailsMapper";
import { WithTypeContaining } from "../../../types/utilityTypes";

export type PersonalDetailsState = {
  firstName: string;
  lastName: string;
  middleInitial: string;
  mothersMaidenName: string;
  gender: string;
  spouseFirstName: string;
  spouseMiddleName: string;
  spouseLastName: string;
  dateOfBirth: string;
  cityOfBirth: string;
  placeOfBirthSelection: string;
  placeOfBirthCountry: string;
};

interface PersonalDetailsAction {
  setPersonalDetails: (
    personalInfoValues: WithTypeContaining<PersonalDetailsState>
  ) => void;
  setFirstAndLastName: (
    firstName: string | undefined,
    lastName: string | undefined
  ) => void;
  reset: () => void;
}

const initialState: PersonalDetailsState = {
  firstName: "",
  lastName: "",
  middleInitial: "",
  mothersMaidenName: "",
  gender: "",
  spouseFirstName: "",
  spouseMiddleName: "",
  spouseLastName: "",
  dateOfBirth: "",
  cityOfBirth: "",
  placeOfBirthSelection: "",
  placeOfBirthCountry: "",
};

export const usePersonalDetailsStore = create<
  PersonalDetailsState & PersonalDetailsAction
>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setPersonalDetails: (personalInfoValues) => {
          set(createPersonalDetailsFromForm(personalInfoValues));
        },
        setFirstAndLastName: (firstName, lastName) => {
          if (firstName) {
            set({ firstName });
          }
          if (lastName) {
            set({ lastName });
          }
        },
        reset: () => set(initialState),
      }),
      {
        name: "personal-details-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
