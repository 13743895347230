import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutationState } from "@tanstack/react-query";
import { Logo } from "../../components/common/Logo";
import { Footer } from "../../components/common/Footer";
import globalStyles from "../../globalStyles.module.scss";
import styles from "./LandingPage.module.scss";
import { StartRegistration } from "../../features/initialRegistration/StartRegistration";
import { useInitialRegistrationData } from "../../features/initialRegistration/queries/useInitialRegistrationData";
import { BreadcrumbStepper } from "../../features/breadcrumb/BreadcrumbStepper";
import { useConfigurationQuery } from "../../features/configuration/useConfigurationQuery";
import { UNEXPECTED_EXCEPTION } from "../../api/shared";
import { Spinner } from "../../components/common/Spinner";
import { Error } from "../../components/Error/Error";
import { initialRegistrationMutationKey } from "../../features/initialRegistration/mutations/useInitialRegistrationMutation";
import { useAuthenticationStore } from "../../features/authentication/authenticationStore";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { useAdobeTagPageLoadEvent } from "../../config/useAdobeTagPageLoadEvent";
import { displayCookieBanner } from "../../features/cookies/displayCookieBanner";
import { useShallow } from "zustand/react/shallow";
import { useTranslationState } from "./useTranslationState";

const LandingPage = () => {
  const { t } = useTranslation();
  const country = useProfileStore(useShallow((state) => state.country));
  const { isTranslationLoading } = useTranslationState();

  useInitialRegistrationData();
  useConfigurationQuery();
  useAdobeTagPageLoadEvent();

  useEffect(() => {
    displayCookieBanner(country);
  }, [country]);

  const initialDataMutationStatus = useMutationState({
    filters: { mutationKey: initialRegistrationMutationKey },
    select: (mutation) => mutation.state.status,
  });

  const isAuthenticated = useAuthenticationStore(
    useShallow((state) => state.isAuthenticated)
  );

  const isInitialDataPending = () =>
    initialDataMutationStatus.toString() === "pending";

  if (!isAuthenticated() || isTranslationLoading) {
    return <Spinner isShowing />;
  }

  return (
    <div className={globalStyles.moduleWrapper}>
      <Spinner isShowing={isInitialDataPending()} />
      <Logo />
      <BreadcrumbStepper activeStep={1} />
      <h1 className={styles.onlineProfileHeader} data-testid="page-header">
        {t("LANDING_PAGE_HEADER")}
      </h1>
      <section className="white-container">
        {!isInitialDataPending() && <StartRegistration />}
        <Error
          message={t(UNEXPECTED_EXCEPTION)}
          showOn={initialDataMutationStatus.toString() === "error"}
        />
      </section>
      <Footer />
    </div>
  );
};

export default LandingPage;
