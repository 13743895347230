import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useProfileStore } from "../../../features/initialRegistration/profileStore";
import { useConfigurationStore } from "../../../features/configuration/configurationStore";
import styles from "./Footer.module.scss";
import { useShallow } from "zustand/react/shallow";
import { getCountryConfig } from "../../../config/countries/countryUtil";

type FooterProps = {
  hideVersion?: boolean;
};

export const Footer = ({ hideVersion }: FooterProps) => {
  const { t } = useTranslation();
  const version = useConfigurationStore(useShallow((state) => state.version));

  const country = useProfileStore(useShallow((state) => state.country));
  const contryConfig = getCountryConfig(country);
  const footerLogos = contryConfig?.footerLogos;
  const showCookiePrivacyLink = contryConfig?.showLinkToCookiePolicy;
  const hideFooterTerms = contryConfig?.hideFooterTerms;

  return (
    <div className={styles.footer} data-testid="footer">
      {!hideFooterTerms && (
        <a target="_blank" rel="noopener noreferrer" href={t("TERMS_LINK")}>
          {t("TERMS")}
        </a>
      )}
      <a target="_blank" rel="noopener noreferrer" href={t("PRIVACY_LINK")}>
        {t("PRIVACY")}
      </a>
      {showCookiePrivacyLink && (
        <Link to="/cookiepolicy" target="_blank">
          {t("COOKIE_POLICY")}
        </Link>
      )}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={t("RULES_OF_CONDUCT_LINK")}
      >
        {t("RULES_OF_CONDUCT")}
      </a>
      {footerLogos && (
        <div className={styles.footerLogosWrapper}>
          {footerLogos.map((logo) =>
            logo.urlLink ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={logo.urlLink}
                key={logo.alt}
              >
                <img key={logo.src} alt={logo.alt} src={logo.src} />
              </a>
            ) : (
              <img key={logo.src} alt={logo.alt} src={logo.src} />
            )
          )}
        </div>
      )}
      <span>{t("COPYRIGHT_NOTICE")}</span>
      {hideVersion ? null : (
        <span className={styles.version} data-testid="version">
          {version}
        </span>
      )}
    </div>
  );
};
