import * as React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomInfo } from "../common/CustomInfo";
import { Button } from "../common/Button";
import { VALIDATION_RULES } from "../../config/constants";
import { useProfileStore } from "../../features/initialRegistration/profileStore";
import { sanitize } from "../../utils/sanitizer";
import styles from "./styles.module.scss";
import { openFileInNewTab } from "../../features/files/fileDownloader";
import { useShallow } from "zustand/react/shallow";

interface MembershipAgreementProps {
  confirmationOnChange: () => void;
  agreeAgreement?: boolean;
  disabled: boolean;
}

export const MembershipAgreement = ({
  agreeAgreement = false,
  confirmationOnChange,
  disabled,
}: MembershipAgreementProps) => {
  const { t } = useTranslation();
  const country = useProfileStore(useShallow((state) => state.country));
  const showLegalStatement =
    VALIDATION_RULES[country]?.showLegalStatementOnAgreementPage;

  return (
    <>
      <CustomInfo info={t("TC_CHECKBOX")} />
      <Button
        id="membership-agreement-button"
        onClick={() => openFileInNewTab(t("MEMBERSHIP_AGREEMENT_LINK"))}
        className={styles.membershipAgreementButton}
      >
        {t("MEMBER_AGREEMENT")}
      </Button>
      {showLegalStatement && (
        <div
          className={styles.legalStatementWrapper}
          data-testid="agreement=page-legal-statements"
        >
          <p
            dangerouslySetInnerHTML={{
              __html: sanitize(t("AGREEMENT_PAGE_LEGAL_STATEMENTS")),
            }}
          />
        </div>
      )}
      <div className={styles.agreementCheckboxWrapper}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!agreeAgreement}
              data-testid="agreement-confirmation-checkbox"
              disabled={disabled}
              disableRipple
              id="agreement-confirmation-checkbox"
              name="agreement-confirmation-checkbox"
              onChange={confirmationOnChange}
            />
          }
          label={t("AGREEMENT_ACKNOWLEDGE")}
        />
      </div>
    </>
  );
};
