const REQUEST_ABORTED = "CanceledError";
let abortController = new AbortController();

export const isRequestAborted = (err: Error) => {
  return err.name === REQUEST_ABORTED;
};

export const getAbortController = () => abortController;

export const abortRequest = () => {
  try {
    abortController.abort();
  } catch (err) {
    if (err instanceof Error && err.name !== REQUEST_ABORTED) {
      throw err;
    }
  } finally {
    abortController = new AbortController();
  }
};
