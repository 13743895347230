import * as Sentry from "@sentry/browser";
import { anonymize } from "../utils/anonymize";
import { isAxiosError } from "axios";
import { ErrorEvent, EventHint } from "@sentry/browser";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT_NAME;

function handleSentryBeforeSend(
  event: ErrorEvent,
  hint: EventHint
): ErrorEvent {
  addAxiosContext(event, hint?.originalException);
  return event;
}

function addAxiosContext(event: ErrorEvent, error: unknown) {
  if (isAxiosError(error) && error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
}

export const initializeSentry = () => {
  if (!SENTRY_DSN || import.meta.env.DEV) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: ENVIRONMENT,
    ignoreErrors: ["Network Error", "Request aborted"],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      if (event.user) {
        event.user.email = event.user.email ? anonymize(event.user.email) : "";
      }
      return handleSentryBeforeSend(event, hint);
    },
  });
};
