import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { SetStorage } from "../../stores/storage";
import { RegistrationEvent } from "../events/models/RegistrationEvent";

interface AuthorizationState {
  permittedPaths: Set<string>;
}

interface AuthorizationAction {
  handleEvent(event: RegistrationEvent): void;
  allowPath: (pathname: string) => void;
  allowPaths: (...pathNames: string[]) => void;
  isUserPermittedToPath: (pathname: string) => boolean;
  redirectToLastPermittedPath: () => void;
  reset: () => void;
}

const allowPathsForEvent = (
  event: RegistrationEvent,
  get: () => AuthorizationState & AuthorizationAction
) => {
  switch (event) {
    case RegistrationEvent.SmsSend:
      break;
    case RegistrationEvent.PinCodeVerified:
      get().allowPath("/personalinfo");
      break;
    case RegistrationEvent.OnlineCodesVerified:
      break;
    case RegistrationEvent.PersonalInfoSubmitted:
      get().allowPath("/guarantees");
      break;
    case RegistrationEvent.GoldStandardAccepted:
      get().allowPath("/agreement");
      break;
    case RegistrationEvent.AgreementsAccepted:
      get().allowPath("/payment");
      break;
    case RegistrationEvent.PaymentStarted:
      get().allowPaths("/filuet-confirmation", "/paymentstatus");
      break;
    case RegistrationEvent.RegistrationCompleted:
      get().allowPaths("/orderconfirmation", "/confirmation");
      break;
  }
};

export const useAuthorizationStore = create<
  AuthorizationState & AuthorizationAction
>()(
  devtools(
    persist(
      (set, get) => ({
        permittedPaths: new Set(["/register"]),
        handleEvent: (event) => {
          allowPathsForEvent(event, get);
        },
        allowPath: (pathname) => {
          set({ permittedPaths: get().permittedPaths.add(pathname) });
        },
        allowPaths: (...pathNames) => {
          set({
            permittedPaths: new Set([
              ...Array.from(get().permittedPaths),
              ...pathNames,
            ]),
          });
        },
        isUserPermittedToPath: (pathname) => get().permittedPaths.has(pathname),
        redirectToLastPermittedPath: () => {
          window.location.href = Array.from(get().permittedPaths)[
            get().permittedPaths.size - 1
          ];
        },
        reset: () => {
          set({ permittedPaths: new Set(["/register"]) });
        },
      }),
      {
        name: "authorization-storage",
        storage: SetStorage<AuthorizationState>("permittedPaths"),
      }
    )
  )
);
