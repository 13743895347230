import { useSponsorshipStore } from "./sponsorship/sponsorshipStore";
import { useProfileStore } from "./profileStore";
import { useHmpOwnershipStore } from "../personal-info/hmpOwnership/hmpOwnershipStore";
import { useShippingStore } from "../delivery/shipping/stores/shippingStore";
import { useContractStore } from "../contract/contractStore";
import { usePaymentAcknowledgeStore } from "../payment/stores/paymentAcknowledgeStore";
import { useMarketingStore } from "../personal-info/marketing/MarketingStore";
import { usePickupStore } from "../delivery/pickup/stores/pickupStore";
import { usePaymentIQStore } from "../payment/paymentIQ/stores/paymentIQStore";
import { useTinsStore } from "../personal-info/tins/tinsStore";
import { useDeliveryStore } from "../delivery/stores/deliveryStore";
import { useConfigurationStore } from "../configuration/configurationStore";
import { useHomeAddressStore } from "../personal-info/address/homeAddressStore";
import { usePersonalDetailsStore } from "../personal-info/personalDetails/personalDetailsStore";
import { useRegistrationStore } from "../registration/registrationStore";
import { usePaymentStore } from "../payment/stores/paymentStore";
import { useAuthenticationStore } from "../authentication/authenticationStore";
import { useAuthorizationStore } from "../authorization/authorizationStore";
import { useAttachmentsStore } from "../personal-info/attachments/attachmentsStore";
import { useIdentificationCardStore } from "../identificationCard/identificationCardStore";
import { usePublicAdministrationStore } from "../personal-info/publicAdministration/publicAdministrationStore";

export const resetAllStores = () => {
  useSponsorshipStore.getState().reset();
  useProfileStore.getState().reset();
  useHmpOwnershipStore.getState().reset();
  useProfileStore.getState().reset();
  useShippingStore.getState().reset();
  useContractStore.getState().reset();
  usePaymentAcknowledgeStore.getState().reset();
  useMarketingStore.getState().reset();
  usePublicAdministrationStore.getState().reset();
  usePickupStore.getState().reset();
  usePaymentIQStore.getState().reset();
  useTinsStore.getState().reset();
  useAttachmentsStore.getState().reset();
  useDeliveryStore.getState().reset();
  useConfigurationStore.getState().reset();
  useHomeAddressStore.getState().reset();
  usePersonalDetailsStore.getState().reset();
  useRegistrationStore.getState().reset();
  usePaymentStore.getState().reset();
  useAuthenticationStore.getState().reset();
  useAuthorizationStore.getState().reset();
  useIdentificationCardStore.getState().reset();
};
