import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export interface identificationCardState {
  identificationCardUrl: string;
}
export interface identificationCardAction {
  setIdentificationCardUrl: (url: string) => void;
  reset: () => void;
}

export const useIdentificationCardStore = create<
  identificationCardState & identificationCardAction
>()(
  devtools(
    persist(
      (set) => ({
        identificationCardUrl: "",
        setIdentificationCardUrl: (identificationCardUrl) =>
          set({ identificationCardUrl }),
        reset: () => set({ identificationCardUrl: "" }),
      }),
      {
        name: "identification-card-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
