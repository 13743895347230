import { create } from "zustand/index";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { WithTypeContaining } from "../../../types/utilityTypes";
import { publicAdministrationFromForm } from "./publicAdministrationMapper";

export type PublicAdministrationState = {
  isPublicAdministrationEmployee: string | null;
};

export type PublicAdministrationAction = {
  setPublicAdministration: (
    personalInfoValues: WithTypeContaining<PublicAdministrationState>
  ) => void;
  reset: () => void;
};

const initialState: PublicAdministrationState = {
  isPublicAdministrationEmployee: "NO",
};

export const usePublicAdministrationStore = create<
  PublicAdministrationState & PublicAdministrationAction
>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setPublicAdministration: (personalInfoValues) => {
          set(publicAdministrationFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "public-administration-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
