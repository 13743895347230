import * as React from "react";
import { ForwardedRef, forwardRef, HTMLAttributes } from "react";
import { PatternFormat } from "react-number-format";
import TextField from "@mui/material/TextField";
import classNames from "classnames";

import { ExternalLink } from "../ExternalLink";
import styles from "./styles.module.scss";
import { FormFieldError } from "../../../utils/formsUtils";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FormFieldError | null;
  format?: string | null;
  linkName?: string;
  label?: string;
  noLabelMargin?: boolean;
  linkUrl?: string;
  required?: boolean;
  onLinkClick?: () => void;
  shrinkedLabel?: boolean;
  inputMode?: HTMLAttributes<HTMLInputElement>["inputMode"];
  pattern?: string;
}

const PatternInput = React.forwardRef((props: Props, ref) => {
  return (
    <PatternFormat
      {...props}
      type="text"
      value={props.value as string}
      defaultValue={props.defaultValue as string}
      format={String(props.format)}
      getInputRef={ref}
    />
  );
});

export const Input = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      error,
      format,
      label,
      linkName,
      linkUrl,
      noLabelMargin,
      required,
      onLinkClick,
      shrinkedLabel,
      maxLength,
      ...nativeInputProps
    } = props;

    const inputProps = () => {
      if (format) {
        return {
          inputComponent: PatternInput,
          inputProps: {
            format,
            getInputRef: ref,
            id: props.id,
            "data-testid": props.id,
            name: props.name,
            onBlur: props.onBlur,
            onChange: props.onChange,
            value: props.value as string,
            disabled: props.disabled,
            type: props.type,
          },
        };
      }
      return {
        inputProps: {
          maxLength,
          "data-testid": props.id,
          onKeyDown: props.onKeyDown,
          type: props.type,
          inputMode: props.inputMode,
          pattern: props.pattern,
        },
      };
    };

    return (
      <div data-testid="component-input" className={styles.inputContainer}>
        <div
          className={classNames(styles.labelsContainer, {
            [styles.noLabelMargin]: noLabelMargin,
          })}
        >
          {linkName && (
            <ExternalLink linkUrl={linkUrl} onClick={onLinkClick}>
              {linkName}
            </ExternalLink>
          )}
        </div>
        <TextField
          required={required}
          disabled={nativeInputProps.disabled}
          error={Boolean(error)}
          id={nativeInputProps.id}
          label={label}
          name={nativeInputProps.name}
          onBlur={nativeInputProps.onBlur}
          onChange={nativeInputProps.onChange}
          onCopy={nativeInputProps.onCopy}
          placeholder={nativeInputProps.placeholder}
          value={nativeInputProps.value}
          inputRef={ref}
          defaultValue={nativeInputProps.defaultValue}
          variant="outlined"
          InputProps={inputProps()}
          InputLabelProps={shrinkedLabel ? { shrink: true } : undefined}
        />
      </div>
    );
  }
);
