const defaultExtendedForbiddenPoBoxKeywords = [
  "po123",
  "po 123",
  "p o123",
  "p o 123",
  "p.o123",
  "p.o 123",
  "postbox",
  "post box",
  "postbox123",
  "postboks 123",
  "post boks123",
  "box",
  "boxs123",
  "boxs 123",
  "pobox",
  "po box",
  "box",
  "p.o123",
  "p.o 123",
  "pobox",
  "po box",
  "p.o box",
  "p.o. box",
  "p.o boX",
  "po boX",
  "po boX1",
  "po-box",
  "p o–box",
];

const forbiddenPoBoxKeywordsForGermanLanguage = ["postfach", "c/o"];
const forbiddenPoBoxKeywordsForSpanish = [
  "apartado de correos",
  "apdo. correos",
  "ap. correos",
];

const forbiddenPoBoxKeywordsForItalian = ["Casella Postale", "C.P."];
const forbiddenPoBoxKeywordsForIndonesia = ["OutBox", "Out Box", "OBOx"];
const forbiddenPoBoxKeywordsForTaiwan = [
  "OutBox",
  "Out Box",
  "OBOx",
  "郵政信箱",
  "邮政信箱",
  "信箱",
];

const forbiddenWordsForCountry: { [key: string]: string[] } = {
  "sk-SK": [
    "p.o. box",
    "po box",
    "p.o.box",
    "pobox",
    "p o box",
    "postbox",
    "post box",
    "po–box",
    "po-box",
    "outbox",
    "out box",
  ],
  "cs-CZ": [
    "p.o. box",
    "po box",
    "p.o.box",
    "p o box",
    "postbox",
    "post box",
    "po–box",
    "po-box",
    "outbox",
    "out box",
    "po123",
    "po 123",
    "p o123",
    "p o 123",
    "p.o123",
    "p.o 123",
    "postbox",
    "post box",
    "postbox123",
    "postboks 123",
    "post boks123",
    "boxs123",
    "boxs 123",
    "pobox",
    "po box",
    "p.o123",
    "p.o 123",
    "po box",
    "p.o box",
    "p.o. box",
    "p.o boX",
    "po boX",
    "po boX1",
    "po-box",
    "p o–box",
  ],
  "en-GB": [
    "p.o. box",
    "po box",
    "p.o.box",
    "p o box",
    "postbox",
    "post box",
    "po–box",
    "po-box",
    "outbox",
    "out box",
    "po123",
    "po 123",
    "p o123",
    "p o 123",
    "p.o123",
    "p.o 123",
    "postbox",
    "post box",
    "postbox123",
    "postboks 123",
    "post boks123",
    "boxs123",
    "boxs 123",
    "pobox",
    "po box",
    "p.o123",
    "p.o 123",
    "po box",
    "p.o box",
    "p.o. box",
    "p.o boX",
    "po boX",
    "po boX1",
    "po-box",
    "p o–box",
  ],
  "de-DE": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForGermanLanguage,
  ],
  "de-AT": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForGermanLanguage,
  ],
  "de-CH": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForGermanLanguage,
  ],
  "fr-CH": defaultExtendedForbiddenPoBoxKeywords,
  "it-CH": defaultExtendedForbiddenPoBoxKeywords,
  "it-IT": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForItalian,
  ],
  "es-ES": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForSpanish,
  ],
  "id-ID": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForIndonesia,
  ],
  "zh-TW": [
    ...defaultExtendedForbiddenPoBoxKeywords,
    ...forbiddenPoBoxKeywordsForTaiwan,
  ],
  "en-My": [...defaultExtendedForbiddenPoBoxKeywords],
  "ms-MY": [...defaultExtendedForbiddenPoBoxKeywords],
  "zh-MY": [...defaultExtendedForbiddenPoBoxKeywords],
};

const defaultForbiddenPoBoxKeywords = [
  "p.o. box",
  "po box",
  "p.o.box",
  "pobox",
  "p o box",
  "postbox",
  "post box",
  "po-box",
  "p o – box",
  "outbox",
  "out box",
  "obox",
];

export const getForbiddenWordsForCountry = (country: string) =>
  forbiddenWordsForCountry[country] || defaultForbiddenPoBoxKeywords;
