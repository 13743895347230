import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import {
  CountryFormConfiguration,
  DEFAULT_COUNTRY_CODES,
  DEFAULT_NAMES_PATTERN,
} from "../constants";
import {
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";

export const Estonia: CountryFormConfiguration = {
  id: "et-EE",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      middleInitial: {
        name: "middleInitial",
        label: "INITIALS",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: false },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "MIDDLE_INITIAL_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.middleInitial,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("et-EE"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: { value: /^\d{5}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.registrationNumber,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 4,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("et-EE"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: { value: /^\d{5}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
  },
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: defaultCountryConfigFieldLengths.city,
  postalCodeLength: 5,
  postalCodeMask: false,
  postalCodePattern: /^\d{5}$/,
  postalCodeRequired: true,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: true,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: true,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: true,
  supportedCountryCodes: DEFAULT_COUNTRY_CODES,
} as const;
