import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomInfo } from "../common/CustomInfo";
import { Button } from "../common/Button";
import { ErrorMessage } from "../ErrorMessage";
import styles from "./styles.module.scss";
import GoldStandardImage from "../../assets/images/gold_standard.jpg";
import { GoldStandardBenefits } from "./GoldStandardBenefits";
import { openFileInNewTab } from "../../features/files/fileDownloader";
import { Spinner } from "../common/Spinner";
import {
  RegistrationEvent,
  useEventStore,
} from "../../features/events/eventStore";
import { useShallow } from "zustand/react/shallow";

type GoldStandardProps = {
  disabled: boolean;
};

export const GoldStandard = ({ disabled }: GoldStandardProps) => {
  const { t } = useTranslation();
  const [agreeGuarantees, setAgreeGuarantees] = React.useState<boolean>(false);
  const [showErrorMsg, setShowErrorMsg] = React.useState<boolean>(false);
  const nodeRef = React.useRef(null);
  const [isPending, startTransition] = React.useTransition();
  const navigate = useNavigate();
  const publish = useEventStore(useShallow((state) => state.publish));

  const handleConfirmation = () => {
    if (agreeGuarantees) {
      publish(RegistrationEvent.GoldStandardAccepted);
      return startTransition(() => navigate("/agreement"));
    }
    return !showErrorMsg ? setShowErrorMsg(!showErrorMsg) : null;
  };

  const confirmationCheckboxHandler = () => {
    setAgreeGuarantees(!agreeGuarantees);

    if (showErrorMsg) {
      setShowErrorMsg(!showErrorMsg);
    }
  };

  return (
    <>
      <Spinner isShowing={isPending} />
      <section
        className={classNames("white-container", styles.goldStandard)}
        data-testid="gold-standard"
      >
        <img
          className={styles.image}
          src={GoldStandardImage}
          alt="Herbalife Gold Standard guarantees"
        />
        <CustomInfo info={t("COMMITMENT_STATEMENT")} />
        <GoldStandardBenefits />
        <Button
          className={styles.button}
          onClick={() => openFileInNewTab(t("GOLD_STANDARDS_LINK"))}
        >
          {t("GSG")}
        </Button>
        <div className={styles.gsgCheckboxWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreeGuarantees}
                data-testid="gold-standard-checkbox"
                disabled={disabled}
                disableRipple
                id="gold-standard-checkbox"
                name="gold-standard-checkbox"
                onChange={confirmationCheckboxHandler}
              />
            }
            label={t("GSG_ACKNOWLEDGE")}
          />
        </div>
        <section className={styles.confirmation}>
          <section className={styles.missing}>
            <CSSTransition
              in={showErrorMsg && !agreeGuarantees}
              classNames="expandable-section"
              nodeRef={nodeRef}
              timeout={300}
              unmountOnExit
            >
              <ErrorMessage
                className={styles.error}
                data-testid="missing-confirmation"
              >
                {t("GSG_ERROR")}
              </ErrorMessage>
            </CSSTransition>
          </section>
          <Button
            id="gold-standard-confirmation-button"
            hasDisabledStyle={!agreeGuarantees}
            onClick={handleConfirmation}
          >
            {t("CONFIRM_CONTINUE")}
          </Button>
        </section>
      </section>
    </>
  );
};
