import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import {
  CountryFormConfiguration,
  DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
  DEFAULT_COUNTRY_CODES,
  DEFAULT_NAMES_PATTERN,
} from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  defaultShippingAddressCareOfNameValidation,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";
import { VisibleOnRule } from "../model/VisibleOnRule";
import { UPLOAD_FILE_INVALID_EXTENSION } from "../../features/personal-info/attachments/validations/attachmentValidations";
import dayjs from "dayjs";

export const Belarus: CountryFormConfiguration = {
  id: "ru-BY",
  forms: {
    personalInfo: {
      beenMemberBefore: {
        name: "beenMemberBefore",
        label: "PREVIOUS_MEMBERSHIP",
        labelExtraClassNames: ["enlargedLabelText"],
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      lastName: {
        name: "lastName",
        label: "LAST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "LAST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.lastName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      firstName: {
        name: "firstName",
        label: "FIRST_NAME",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "FIRST_NAME_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.firstName,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "FIRST_NAME_SIZE",
          },
          trim: true,
        },
      },
      middleInitial: {
        name: "middleInitial",
        label: "INITIALS",
        type: "text",
        sanitize: true,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          hasNoOnlySpecialSigns: {
            value: true,
            errorMessage: "MIDDLE_INITIAL_SIZE",
          },
          maxLength: {
            value: defaultCountryConfigFieldLengths.middleInitial,
            errorMessage: "TOO_LONG",
          },
          pattern: {
            value: DEFAULT_NAMES_PATTERN,
            errorMessage: "LAST_NAME_SIZE",
          },
          trim: true,
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("ru-BY"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 60,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 6, errorMessage: "TOO_LONG" },
          minLength: { value: 6, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: { value: /^\d{6}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
      dateOfBirth: {
        name: "dateOfBirth",
        label: "BIRTHDAY",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          adultAge: { value: 18, errorMessage: "TOO_YOUNG" },
        },
      },
      isResident: {
        name: "isResident",
        label: "REGISTERED_RESIDENT",
        type: "radio",
        options: [
          { value: true, label: "YES" },
          { value: false, label: "NO" },
        ],
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
        },
        classNames: ["centerJustified"],
        validationErrorStyling: ["textCentered"],
      },
      registrationNumber0: {
        name: "registrationNumber0",
        label: "REGISTRATION_NUMBER0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.registrationNumber,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 4,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumber0IssueDate: {
        name: "registrationNumber0IssueDate",
        label: "ISSUE_DATE",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(1, "day"),
            errorMessage: "WRONG_ISSUE_DATE",
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumber0ExpiryDate: {
        name: "registrationNumber0ExpiryDate",
        label: "EXPIRY_DATE",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          minDate: {
            value: dayjs().add(1, "day"),
            errorMessage: "WRONG_EXPIRY_DATE",
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumber0Attachment0: {
        name: "registrationNumber0-attachment0",
        label: "UPLOAD_FILE_INFO_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumber0Attachment1: {
        name: "registrationNumber0-attachment1",
        label: "UPLOAD_FILE_INFO_RESIDENT1",
        type: "file",
        index: 1,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumber0Attachment2: {
        name: "registrationNumber0-attachment2",
        label: "UPLOAD_FILE_INFO_RESIDENT2",
        type: "file",
        index: 2,
        attachmentType: AttachmentType.TAX_NUMBER,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident0: {
        name: "registrationNumberNonResident0",
        label: "REGISTRATION_NUMBER_NON_RESIDENT0",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.registrationNumber,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 4,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident0IssueDate: {
        name: "registrationNumberNonResident0IssueDate",
        label: "ISSUE_DATE",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(1, "day"),
            errorMessage: "WRONG_ISSUE_DATE",
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident0ExpiryDate: {
        name: "registrationNumberNonResident0ExpiryDate",
        label: "EXPIRY_DATE",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          minDate: {
            value: dayjs().add(1, "day"),
            errorMessage: "WRONG_EXPIRY_DATE",
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident0Attachment0: {
        name: "registrationNumberNonResident0-attachment0",
        label: "UPLOAD_FILE_INFO_NON_RESIDENT0",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.RESIDENCE_PERMIT,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident0Attachment1: {
        name: "registrationNumberNonResident0-attachment1",
        label: "UPLOAD_FILE_INFO_NON_RESIDENT1",
        type: "file",
        index: 1,
        attachmentType: AttachmentType.RESIDENCE_PERMIT,
        validation: {
          required: { value: false, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident1: {
        name: "registrationNumberNonResident1",
        label: "REGISTRATION_NUMBER_NON_RESIDENT1",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.registrationNumber,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          minLength: {
            value: 4,
            errorMessage: "REGISTRATION_NUMBER_SIZE",
          },
          pattern: {
            value: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
            errorMessage: "REGISTRATION_NUMBER_INVALID_PATTERN",
          },
          trim: true,
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident1IssueDate: {
        name: "registrationNumberNonResident1IssueDate",
        label: "ISSUE_DATE",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxDate: {
            value: dayjs().subtract(1, "day"),
            errorMessage: "WRONG_ISSUE_DATE",
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident1ExpiryDate: {
        name: "registrationNumberNonResident1ExpiryDate",
        label: "EXPIRY_DATE",
        type: "date",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          minDate: {
            value: dayjs().add(1, "day"),
            errorMessage: "WRONG_EXPIRY_DATE",
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident1Attachment0: {
        name: "registrationNumberNonResident1-attachment0",
        label: "UPLOAD_FILE_INFO_NON_RESIDENT2",
        type: "file",
        index: 0,
        attachmentType: AttachmentType.RESIDENCE_PERMIT,
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
      registrationNumberNonResident1Attachment1: {
        name: "registrationNumberNonResident1-attachment1",
        label: "UPLOAD_FILE_INFO_NON_RESIDENT3",
        type: "file",
        index: 1,
        attachmentType: AttachmentType.RESIDENCE_PERMIT,
        validation: {
          required: { value: false, errorMessage: "REQUIRED" },
          acceptedExtensions: {
            value: DEFAULT_ACCEPTED_ATTACHMENT_EXTENSIONS,
            errorMessage: UPLOAD_FILE_INVALID_EXTENSION,
          },
        },
        rule: {
          visibleOn: [
            "isResident",
            (isResident: boolean) => isResident != null && !isResident,
          ] as VisibleOnRule<boolean>,
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: defaultShippingAddressCareOfNameValidation,
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("ru-BY"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: 60,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 6, errorMessage: "TOO_LONG" },
          minLength: { value: 6, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: { value: /^\d{6}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
  },
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: defaultCountryConfigFieldLengths.city,
  postalCodeMask: false,
  postalCodeLength: 6,
  postalCodePattern: /^\d{6}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: 60,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: true,
  middleInitial: true,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: true,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: false,
  showDocumentationBox: false,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: false,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  supportedCountryCodes: DEFAULT_COUNTRY_CODES,
  isDataPrivacyAcknowledgement: true,
  isUpperDataPrivacyAcknowledgement: true,
  mandatoryDataPrivacyAcknowledgement: true,
} as const;
