import React, { ChangeEvent, ForwardedRef, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { FormFieldError } from "../../../utils/formsUtils";
import { Input } from "../../../components/common/Input";

type SponsorIdInputProps = {
  disabled: boolean;
  error: FormFieldError;
  onChange: (value: string) => void;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string | null | undefined;
};

export const SponsorIdInput = forwardRef(
  (props: SponsorIdInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { t } = useTranslation();

    const { disabled, error, onBlur, onChange, value } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange && onChange(event.target?.value);
    return (
      <Input
        disabled={disabled}
        required
        error={error}
        id="sponsorId"
        label={t("SPONSOR_ID")}
        maxLength={10}
        name="sponsorId"
        onBlur={onBlur}
        onChange={handleChange}
        type="text"
        value={value || ""}
        ref={ref}
      />
    );
  }
);
