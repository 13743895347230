import axios, { AxiosResponse } from "axios";
import * as Sentry from "@sentry/browser";
import {
  AUTH_HEADER,
  SERVER_API_URL,
  SERVICE_UNAVAILABLE,
  TIMEOUT,
  UNAUTHORIZED,
} from "./constants";
import { useAuthenticationStore } from "../features/authentication/authenticationStore";
import {
  useFirebaseStore,
  USER_SESSION_EXPIRED_EVENT,
} from "../features/firebase/firebaseStore";
import { ResponseError } from "../features/common/api/ResponseError";

axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = useAuthenticationStore.getState().getToken();
    if (token) {
      /* eslint no-param-reassign: ["error", { "props": false }] */
      config.headers[AUTH_HEADER] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error as Error)
);

const getAuthTokenFromHeader = (response: AxiosResponse) => {
  const bearerToken = response?.headers[AUTH_HEADER] as string;
  if (bearerToken && bearerToken.slice(0, 7) === "Bearer ") {
    return bearerToken.slice(7, bearerToken.length);
  }

  return null;
};

const redirect = (status?: number) => {
  if (status === SERVICE_UNAVAILABLE) {
    window.location.href = "/maintenance";
  }

  if (status === UNAUTHORIZED) {
    useAuthenticationStore.getState().removeToken();
    useFirebaseStore
      .getState()
      .sendFirebaseEvent(USER_SESSION_EXPIRED_EVENT, {});
    window.location.href = import.meta.env.VITE_SSO_COUNTRY_SELECTION_LINK;
  }
};

axios.interceptors.response.use(
  (response) => {
    const token = getAuthTokenFromHeader(response);
    if (token) {
      useAuthenticationStore.getState().setToken(token);
    }
    return response;
  },
  (error: ResponseError) => {
    if (error?.config?.baseURL === SERVER_API_URL) {
      redirect(error?.response?.status);
      return Promise.reject(error as Error);
    } else {
      Sentry.captureException(error);
    }
  }
);
